// Imports
import Vue from "vue";
import Router from "vue-router";
import { layout, route } from "@/util/routes";
import store from "../store/index.js";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (to.hash) return { selector: to.hash };
		if (savedPosition) return savedPosition;
		return { x: 0, y: 0 };
	},
	routes: [
		layout("fullScreen", [
			route("Homepage", null, "", { requiresAuth: false }),
			route("Registration", null, "/registration", {
				requiresAuth: false
			}),
			route("StudentLogin", null, "/student/login", {
				requiresAuth: false
			})
		]),

		layout("wideAside", [
			route("common/Login", null, "login", { requiresAuth: false })
		]),
		layout("wideAside", [
			route("StudentRegistration", null, "register-student", {
				requiresAuth: false
			})
		]),
		layout("studentDefault", [
			route(
				"student/views/dashboard/StudentDashboard",
				null,
				"student/dashboard",
				{
					requiresAuth: true
				}
			),
			route(
				"student/views/courses/StudentCourses",
				null,
				"student/courses",
				{
					requiresAuth: true
				}
			),
			route("student/views/course/Course", null, "student/course", {
				requiresAuth: true
			}),
			route("student/views/quiz/Quiz", null, "student/quiz", {
				requiresAuth: true
			}),
			route(
				"student/views/course-results/CourseResults",
				null,
				"student/results",
				{
					requiresAuth: true
				}
			),
			route(
				"student/views/certificates/StudentCertificates",
				null,
				"student/certificates",
				{
					requiresAuth: true
				}
			)
		]),

		layout("adminDefault", [
			route("admin/admin-dashboard/AdminDashboard", null, "/admin", {
				requiresAuth: true,
				requiresAdmin: true
			}),
			route("admin/courses/Courses", null, "/admin/courses", {
				requiresAuth: true,
				requiresAdmin: true
			}),
			route(
				"admin/course-editor/CourseEditor",
				null,
				"/admin/courses-editor",
				{
					requiresAuth: true,
					requiresAdmin: true
				},
				"id"
			),
			route("admin/new-quiz/NewQuiz", null, "/admin/new-quiz", {
				requiresAuth: true,
				requiresAdmin: true
			}),
			route(
				"admin/quiz-settings/QuizSettings",
				null,
				"/admin/quiz-settings/quiz-settings",
				{
					requiresAuth: true,
					requiresAdmin: true
				},
				"id"
			),
			route(
				"admin/quiz-editor/QuizEditor",
				null,
				"/admin/quiz-editor",
				{
					requiresAuth: true,
					requiresAdmin: true
				},
				"id"
			),
			route(
				"admin/quiz-editor/quiz-editor-new-question/QuizEditorAddNewQuestion",
				null,
				"/admin/new-quiz/new-question",
				{
					requiresAuth: true,
					requiresAdmin: true
				},
				"id"
			),
			route(
				"admin/quiz-editor/quiz-editor-new-question/QuizEditorAddNewQuestion",
				null,
				"/admin/new-quiz/edit-question",
				{
					requiresAuth: true,
					requiresAdmin: true
				},
				"id"
			),
			route(
				"admin/course-publish/CoursePublish",
				null,
				"/admin/course-publish",
				{
					requiresAuth: true,
					requiresAdmin: true
				},
				"id"
			),
			route(
				"admin/certificates/Certificates",
				null,
				"/admin/certificates",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route("admin/quizes/Quizes", null, "/admin/quizes", {
				requiresAuth: true,
				requiresAdmin: true
			}),

			route(
				"admin/organization-settings/OrganizationSettings",
				null,
				"/admin/organization/settings",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/new-user/NewUser",
				null,
				"/admin/organization/settings/new-user",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/new-course/NewCourse",
				null,
				"/admin/new-course/step-1",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/new-course/NewCourseCourseName",
				null,
				"/admin/new-course/step-2",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/new-course/NewCourseCourseSettings",
				null,
				"/admin/new-course/course-settings",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/new-course/NewCourseAssignQuiz",
				null,
				"/admin/new-course/assign-quiz",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/new-course/NewCourseAssignUsers",
				null,
				"/admin/new-course/assign-users",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/quiz-settings/QuizAssign",
				null,
				"/admin/quiz-settings/assign-course",
				{ requiresAuth: true, requiresAdmin: true },
				"id"
			),
			route(
				"admin/course-settings/CourseSettings",
				null,
				"/admin/course-settings",
				{ requiresAuth: true, requiresAdmin: true },
				"id"
			),
			route(
				"admin/question-bank/QuestionBank",
				null,
				"/admin/question-bank",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/response-bank/ResponseBank",
				null,
				"/admin/response-bank",
				{ requiresAuth: true, requiresAdmin: true }
			),
			route(
				"admin/course-analytics/CourseAnalytics",
				null,
				"/admin/course-analytics",
				{ requiresAuth: true, requiresAdmin: true },
				"id"
			),
			route(
				"admin/quiz-analytics/QuizAnalytics",
				null,
				"/admin/quiz-analytics",
				{ requiresAuth: true, requiresAdmin: true },
				"id"
			)
		]),
		layout("fullScreen", [route("Error", null, "*")])
	]
});

router.beforeEach(function (to, from, next) {
	store.getters.isLogged && store.dispatch("autoLogin");
	if (to.meta?.requiresAuth && localStorage.getItem("isLogged") !== "true") {
		next({ name: "Login", query: from.query });
	} else if (
		to.meta?.requiresAdmin &&
		localStorage.getItem("isAdmin") !== "true"
	) {
		next({ name: "StudentDashboard", query: from.query });
	} else if (to.meta?.requiresAuth === false) {
		next({ query: from.query });
	} else {
		next({ query: from.query });
	}
});

export default router;
